<template>
  <page-header-wrapper>
    <template v-slot:content>
      <a-row type="flex" justify="space-between" :gutter="8">
        <a-col :span="3"
        ><a-select
          placeholder="-Brand-"
          style="width: 100%"
          showSearch
          v-model="brandId"
          optionFilterProp="title"
          @change="changeBrand"
        >
          <a-select-option v-for="item in brandListData" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select></a-col>
        <a-col :span="14">
          <a-radio-group v-model="typeRadioGroup" button-style="solid" @change="changeGroup">
            <a-radio-button :value="1">
              Today
            </a-radio-button>
            <a-radio-button :value="2">
              Yesterday
            </a-radio-button>
            <a-radio-button :value="3">
              Last Week
            </a-radio-button>
            <a-radio-button :value="4">
              Last Month
            </a-radio-button>

            <a-radio-button :value="5">
              This Year
            </a-radio-button>
          </a-radio-group>
        </a-col>
        <a-col :span="4">
          <a-range-picker v-model="rangePickerTime" @change="changeRangePicker" />
        </a-col>
        <a-col auto>
<!--          <a-button type="primary">Chart Setting</a-button>-->
          <a-button type="primary">Search</a-button>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="8" style="margin-top: 20px">
        <a-col :span="16">
          <div style="width: 100%;height: 400px;">
           <a-row type="flex":gutter="8" style="width: 100%;">
             <a-col :span="8">
               <a-card title="New User新注册用户" style="height: 400px;">
                 <div style="display: flex;align-items: center;justify-content: space-between" v-for="(item,index) in userList">
                   <div>{{ item.email }}</div>
                   <div>{{ item.time }}</div>
                 </div>
               </a-card>
             </a-col>
             <a-col :span="16">
               <div style="width: 100%;height: 400px;">
                 <a-card title="Activated Products Thermal Map活跃产品热力图" style="height: 400px;">
                   <div style="background: #ECECEC; padding: 30px">
                     <a-row :gutter="16">
                       <a-col :span="8">
                         <a-card>
                           <div>
                             Total Products
                           </div>
                           <div>{{ Number(activatedProductsThermal.totalProducts) }}</div>
<!--                           <a-statistic-->
<!--                             title="Total Products"-->
<!--                             :value="Number(activatedProductsThermal.totalProducts)"-->
<!--                             :precision="0"-->
<!--                             suffix=""-->
<!--                             :value-style="{ color: '#3f8600' }"-->
<!--                             style="margin-right: 50px"-->
<!--                           >-->

<!--                           </a-statistic>-->
                         </a-card>
                       </a-col>
                       <a-col :span="8">
                         <a-card>
                           <div>
                             Activated Total
                           </div>
                           <div>{{ Number(activatedProductsThermal.activatedTotal) }}</div>
<!--                           <a-statistic-->
<!--                             title="Activated Total"-->
<!--                             :value="Number(activatedProductsThermal.activatedTotal)"-->
<!--                             :precision="0"-->
<!--                             suffix=""-->
<!--                             class="demo-class"-->
<!--                             :value-style="{ color: '#cf1322' }"-->
<!--                           >-->
<!--                           </a-statistic>-->
                         </a-card>
                       </a-col>

                       <a-col :span="8">
                         <a-card>
                           <div>
                             Activated Today
                           </div>
                           <div>{{ Number(activatedProductsThermal.activatedToday) }}</div>
<!--                           <a-statistic-->
<!--                             title="Activated Today"-->
<!--                             :value="Number(activatedProductsThermal.activatedToday)"-->
<!--                             :precision="0"-->
<!--                             suffix=""-->
<!--                             class="demo-class"-->
<!--                             :value-style="{ color: '#cf1322' }"-->
<!--                           >-->
<!--                           </a-statistic>-->
                         </a-card>
                       </a-col>
                     </a-row>
                   </div>
                 </a-card>
               </div>
             </a-col>
           </a-row>
          </div>
          <a-row type="flex":gutter="8" style="width: 100%;margin-top: 10px;">
            <a-col :span="24">
              <a-card title="Active Products Date Line Chart 活跃产品同比环比表-按年">
                <div id="activeProductsDateLine" style="width: 100%;height: 600px"></div>
              </a-card>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="8">
          <a-card title="Ranking of Active Products 活跃产品排名" style="margin-top: 10px;">
            <div id="rankActiveProducts" style="width: 100%;height: 250px"></div>
          </a-card>
          <a-card title="Active Flavors Ranking 活跃口味排名" style="margin-top: 10px;">
            <div id="activeFlavorsRanking"  style="width: 100%;height: 250px"></div>
          </a-card>
<!--          <a-card title="The Popular Curves Ranking受欢迎的功率曲线饼图" style="margin-top: 10px;">-->
<!--            <div id="popularCurvesRanking" style="width: 100%;height: 250px" ></div>-->
<!--          </a-card>-->
          <a-card title="Activated Ingredients(Top10)排名前10的活跃成分饼图" style="margin-top: 10px;">
            <div id="activatedIngredients" style="width: 100%;height: 250px" ></div>
          </a-card>
        </a-col>
      </a-row>

    </template>
  </page-header-wrapper>
</template>

<script>
import { timeFix } from '@/utils/util'
import { mapGetters } from 'vuex'
import { PageHeaderWrapper } from '@/components/ProLayout'
import ruoyiLogo from '@/assets/projects/ruoyi.png'
import antdvLogo from '@/assets/projects/antdv.png'
import { getUserProfile } from '@/api/system/user'
import {listBrand} from "@/api/cigarette/brand";
import * as echarts from 'echarts';
import {getHomeData} from "@/api/cigarette/batch";
export default {
  name: 'Index',
  components: {
    PageHeaderWrapper
  },
  data () {
    return {
      rangePickerTime:[],
      // 贡献者
      contributors: [
        {
          name: 'wangze',
          avatar: 'https://portrait.gitee.com/uploads/avatars/user/1662/4988475_fuzui_1586973704.png!avatar60',
          email: '73400@163.com'
        }
      ],
      // 赞助
      sponsorList: [
        {
          key: 'RuoYi',
          tab: 'RuoYi'
        },
        {
          key: 'Antdv',
          tab: 'Antdv'
        }
      ],
      noTitleKey: 'RuoYi',
      timeFix: timeFix(),
      // 用户信息
      user: {
        dept: {
          deptName: ''
        }
      },
      roleGroup: {},
      postGroup: {},
      // 致谢项目
      projects: [
        {
          logo: ruoyiLogo,
          name: 'RuoYi-Vue',
          description: '基于SpringBoot，Spring Security，JWT，Vue & Element 的前后端分离权限管理系统。',
          website: 'http://ruoyi.vip',
          downloadUrl: 'https://gitee.com/y_project/RuoYi-Vue'
        },
        {
          logo: antdvLogo,
          name: 'Ant Design Vue',
          description: 'An enterprise-class UI components based on Ant Design and Vue. ',
          website: 'https://antdv.com',
          downloadUrl: 'https://github.com/vueComponent/ant-design-vue/'
        },
        {
          logo: antdvLogo,
          name: 'Antd Pro Vue',
          description: 'An out-of-box UI solution for enterprise applications as a Vue boilerplate. based on Ant Design of Vue.',
          website: 'https://pro.antdv.com',
          downloadUrl: 'https://github.com/vueComponent/ant-design-vue/'
        }
      ],
      brandListData:[],
      userList:[],
      activatedProductsThermal:{
        activatedToday:0,
        activatedTotal:0,
        totalProducts:0
      },
      typeRadioGroup:5,
      brandId:null,
      createBeginTime: null ,
      createEndTime: null,
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname'
    ])
  },
  created () {
    // this.getUser()
    this.getBrandSkuList();
  },
  mounted () {
    this.getPostInfo();
    // this.renderProdcutsChart();
    // this.renderProductChart();
    // this.renderPopularCurvesRankingChart();
    // this.renderActivatedIngredientsChart();
    // this.renderActiveProductsDateLineChart();
  },
  methods: {
    changeRangePicker(e,str) {
      this.typeRadioGroup = 6;
      console.log(e)
      console.log(str);
      this.createBeginTime = str[0]
      this.createEndTime = str[1];
      this.getPostInfo();
    },
    changeGroup(e) {
      console.log(e.target.value);
      this.typeRadioGroup = e.target.value;
      this.rangePickerTime = [];
      this.createBeginTime = null;
      this.createEndTime = null;
      this.getPostInfo();
    },
    changeBrand(e) {
      this.getPostInfo();
    },
    getPostInfo() {
      getHomeData({
        brandId: this.brandId,
        type: this.typeRadioGroup,
        createBeginTime:this.createBeginTime,
        createEndTime : this.createEndTime,
      }).then(res => {
        this.userList = res.userList;
        this.activatedProductsThermal = res.activatedProductsThermal;
        this.renderProdcutsChart(res.activeSkuRanking);
        this.renderProductChart(res.activeFlavorsRanking);
        this.renderActiveProductsDateLineChart(res.todayYearData,res.lastYearData);
        this.renderActivatedIngredientsChart(res.activatedIngredients);
      })
    },
    renderActiveProductsDateLineChart(todayYearData,lastYearData) {
      let lastToday = lastYearData.map(i => Number(i.value));
      let todayToday = todayYearData.map(i => Number(i.value));

      let growData = [];
      if(todayToday[0] === 0) {
        growData.push(0)
      } else {
        if(lastToday[0] === 0) {
          growData.push(todayToday[0] * 100)
        } else {
          growData.push((todayToday[0] / lastToday[0]).toFixed(0) * 100)
        }
      }

      if(todayToday[1] === 0) {
        growData.push(0)
      } else {
        if(lastToday[1] === 0) {
          growData.push(todayToday[1] * 100)
        } else {
          growData.push((todayToday[1] / lastToday[1]).toFixed(0) * 100)
        }
      }

      if(todayToday[2] === 0) {
        growData.push(0)
      } else {
        if(lastToday[2] === 0) {
          growData.push(todayToday[2] * 100)
        } else {
          growData.push((todayToday[2] / lastToday[2]).toFixed(0) * 100)
        }
      }

      if(todayToday[3] === 0) {
        growData.push(0)
      } else {
        if(lastToday[3] === 0) {
          growData.push(todayToday[3] * 100)
        } else {
          growData.push((todayToday[3] / lastToday[3]).toFixed(0) * 100)
        }
      }

      if(todayToday[4] === 0) {
        growData.push(0)
      } else {
        if(lastToday[4] === 0) {
          growData.push(todayToday[4] * 100)
        } else {
          growData.push((todayToday[4] / lastToday[4]).toFixed(0) * 100)
        }
      }

      if(todayToday[5] === 0) {
        growData.push(0)
      } else {
        if(lastToday[5] === 0) {
          growData.push(todayToday[5] * 100)
        } else {
          growData.push((todayToday[5] / lastToday[5]).toFixed(0) * 100)
        }
      }
      if(todayToday[6] === 0) {
        growData.push(0)
      } else {
        if(lastToday[6] === 0) {
          growData.push(todayToday[6] * 100)
        } else {
          growData.push((todayToday[6] / lastToday[6]).toFixed(0) * 100)
        }
      }

      if(todayToday[7] === 0) {
        growData.push(0)
      } else {
        if(lastToday[7] === 0) {
          growData.push(todayToday[7] * 100)
        } else {
          growData.push((todayToday[7] / lastToday[7]).toFixed(0) * 100)
        }
      }

      if(todayToday[8] === 0) {
        growData.push(0)
      } else {
        if(lastToday[8] === 0) {
          growData.push(todayToday[8] * 100)
        } else {
          growData.push((todayToday[8] / lastToday[8]).toFixed(0) * 100)
        }
      }

      if(todayToday[9] === 0) {
        growData.push(0)
      } else {
        console.log(todayToday[9])
        console.log(lastToday[9])
        if(lastToday[9] === 0) {
          growData.push(todayToday[9] * 100)
        } else {
          growData.push((todayToday[9] / lastToday[9]).toFixed(0) * 100)
        }
      }

      if(todayToday[10] === 0) {
        growData.push(0)
      } else {
        if(lastToday[10] === 0) {
          growData.push(todayToday[10] * 100)
        } else {
          growData.push((todayToday[10] / lastToday[10]).toFixed(0) * 100)
        }
      }

      if(todayToday[11] === 0) {
        growData.push(0)
      } else {
        if(lastToday[11] === 0) {
          growData.push(todayToday[11] * 100)
        } else {
          growData.push((todayToday[11] / lastToday[11]).toFixed(0) * 100)
        }
      }

      console.log('growData')
      console.log(growData)
      const chartDom = document.getElementById(`activeProductsDateLine`);
      const myChart = echarts.init(chartDom);
      const option =  {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: [lastYearData[0].name.split('-')[0], todayYearData[0].name.split('-')[0], 'Growth Rate'],
          bottom:'0',
        },
        xAxis: [
          {
            type: 'category',
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sept','Oct','Nov','Dec'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            interval: 50,
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: lastYearData[0].name.split('-')[0],
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '';
              }
            },
            data: lastToday
          },
          {
            name: todayYearData[0].name.split('-')[0],
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '';
              }
            },
            data: todayToday
          },
          {
            name: 'Growth Rate',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: growData
          }
        ]
      };
      myChart.setOption(option);
    },

    renderActivatedIngredientsChart(activatedIngredients) {
      const chartDom = document.getElementById(`activatedIngredients`);
      const myChart = echarts.init(chartDom);
      const option =  {
        title: {
          text: 'Referer of a Website',
          subtext: 'Fake Data',
          left: 'center',
          show: false
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '0',
          type: 'scroll',
          height:50
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: activatedIngredients,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    },
  renderPopularCurvesRankingChart() {

    const chartDom = document.getElementById(`popularCurvesRanking`);
    const myChart = echarts.init(chartDom);
    const option =  {
      title: {
        text: 'Referer of a Website',
        subtext: 'Fake Data',
        left: 'center',
        show: false
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '70%',
          data: [
            { value: 1048, name: 'Search Engine' },
            { value: 735, name: 'Direct' },
            { value: 580, name: 'Email' },
            { value: 484, name: 'Union Ads' },
            { value: 300, name: 'Video Ads' }
          ],
          label: {
            normal: {
              show: false
            }
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    myChart.setOption(option);
},
    renderProductChart(item) {
      const chartDom = document.getElementById(`activeFlavorsRanking`);
      const myChart = echarts.init(chartDom);
      const option = {
        xAxis: {
          type: 'category',
          data: item.map(i => i.name)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: item.map(i => i.value),
            type: 'bar'
          }
        ]
      };
      myChart.setOption(option);
    },
    //Ranking of Active Products 活跃产品排名
    renderProdcutsChart(item) {
      const chartDom = document.getElementById(`rankActiveProducts`);
      const myChart = echarts.init(chartDom);
      const option = {
        xAxis: {
          type: 'category',
          data: item.map(i => i.name)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: item.map(i => i.value),
            type: 'bar'
          }
        ]
      };
      myChart.setOption(option);
    },
    getBrandSkuList() {
      listBrand({
        status:1
      }).then(res => {
        this.brandListData = res.data;
      })
    },
    // 获取用户信息
    getUser () {
      getUserProfile().then(response => {window.console.log('response 111',response)
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
    },
    onSponsorTabChange (key, type) {
      this[type] = key
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index.less";
  blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
  }
  .project-list {

    .card-title {
      font-size: 0;

      a {
        color: rgba(0, 0, 0, 0.85);
        margin-left: 12px;
        line-height: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .card-description {
      color: rgba(0, 0, 0, 0.45);
      height: 66px;
      line-height: 22px;
      overflow: hidden;
    }

    .project-item {
      display: flex;
      margin-top: 8px;
      overflow: hidden;
      font-size: 12px;
      height: 20px;
      line-height: 20px;

      a {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        flex: 1 1 0;

        &:hover {
          color: #1890ff;
        }
      }

      .download {
        color: rgba(0, 0, 0, 0.25);
        flex: 0 0 auto;
        float: right;
      }
    }

    .ant-card-meta-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }
  }

  .item-group {
    padding: 20px 0 8px 24px;
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.65);
      display: inline-block;
      font-size: 14px;
      margin-bottom: 13px;
      width: 25%;
    }
  }

  .mobile {

    .project-list {

      .project-card-grid {
        width: 100%;
      }
    }

    .more-info {
      border: 0;
      padding-top: 16px;
      margin: 16px 0 16px;
    }

    .headerContent .title .welcome-text {
      display: none;
    }
  }

</style>
