import request from '@/utils/request'


// 查询新-产品批次列表
export function listBatch(query) {
  return request({
    url: '/cigarette/goods-batch/list',
    method: 'get',
    params: query
  })
}

export function getHomeData(data) {
  return request({
    url: '/cigarette/goods-batch/getHomeData',
    method: 'post',
    data: data
  })
}
// 查询新-产品批次分页
export function pageBatch(query) {
  return request({
    url: '/cigarette/goods-batch/page',
    method: 'get',
    params: query
  })
}

// 查询新-产品批次详细
export function getBatch(data) {
  return request({
    url: '/cigarette/goods-batch/detail',
    method: 'get',
    params: data
  })
}

// 新增新-产品批次
export function addBatch(data) {
  return request({
    url: '/cigarette/goods-batch/add',
    method: 'post',
    data: data
  })
}

// 修改新-产品批次
export function updateBatch(data) {
  return request({
    url: '/cigarette/goods-batch/edit',
    method: 'post',
    data: data
  })
}

// 删除新-产品批次
export function delBatch(data) {
  return request({
    url: '/cigarette/goods-batch/delete',
    method: 'post',
    data: data
  })
}
